.about-page {
  min-height: 100vh;

  h2.title {
    font-weight: $bold;
    font-size: 30px;
    line-height: 37px;
    color: $black;

    @include respond-down(small){
      font-size: 20px;
      line-height: 25px;
    }
  }


  .page-title {
    font-weight: $bold;
    font-size: 27px;
    line-height: 37px;
    text-align: center;
    color: $black;
    text-transform: uppercase;
  }

  .hero {
    display: flex;
    width: 100%;
    height: 408px;
    background-image: url('../images/Main/about/about__hero.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    @include respond(middle) {
      background-size: cover;
      background-position: center center;
      height: 350px;
    }
    @include respond(medium) {
      background-size: cover;
      background-position: center center;
      height: 260px;
    }
    @include respond(small) {
      height: 150px;
      background-size: cover;
      background-position: center center;
    }
  }

  .paragraph {
    &, p {
      font-size: 18px;
      line-height: calc(28 / 18);
      color: #191A19;
      @include respond-down(middle) {
        font-size: 16px;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(small) {
      display: block;
    }

    .text {
      max-width: 459px;
      font-weight: $bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;

      @include respond-down(small){
        font-size: 20px;
        line-height: 25px;
      }
    }

    .button {
      width: 198px;
      height: 52px;
      margin-left: 67px;
      padding: 18px 0;
      @include respond(small) {
        margin: 20px auto 0 auto;
        display: block;
      }
    }
  }

  .today {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include respond(small) {
      display: block;
      .title {
        margin: 0 0 20px;
        text-align: center;
      }
    }

    .video {
      display: block;
      position: relative;
      width: 514px;
      height: 330px;
      background-image: url('../images/temp/video-poster.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      margin-right: 50px;

      &.en{
        background-image: url('../images/temp/video-poster-en.jpg');

      }
      @include respond-down(middle) {
        width: 450px;
        height: 289px;
      }
      @include respond(small) {
        width: 100%;
        height: 240px;
        margin: 20px 0 30px 0;
        background-size: cover;
      }

      .play-button {
        display: block;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -40px;
        margin-top: -40px;
        cursor: pointer;
        border-radius: 50%;
        transform: scale(1);
        transition: all ease-out .2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .text-block {
      .title {
        margin: -10px 0 30px;
        font-weight: $bold;
        font-size: 30px;
        line-height: 37px;
        color: $black;
        @include respond-down(middle) {
          margin: -10px 0 20px;
        }
      }

      &, p, li {
        font-size: 18px;
        line-height: calc(22 / 18);
        color: #191A19;
        @include respond-down(middle) {
          font-size: 16px;
        }
      }

      li {
        max-width: 578px;

        &:not(:last-child) {
          margin-bottom: 20px;
          @include respond-down(middle) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .produce {
    .title {
      text-align: center;

    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: -14px;
      @include respond-down(small) {
        display: block;
        margin: 15px 0 0;
      }

      .left, .right {
        display: flex;
        align-items: center;
        width: 50%;
      }

      .right {
        @include respond-down(small) {
          width: 100%;
          p {
            max-width: none;
            font-size: 15px;
          }
        }
      }

      .magnet {
        display: inline-block;
        width: 155px;
        height: 193px;
        margin-right: 40px;
        flex: 0 0 155px;
        background-image: url('../images/Main/about/magnet.png');
        background-size: contain;
        background-repeat: no-repeat;
        @include respond-down(small) {
          width: 128px;
          height: 160px;
          flex: 0 0 128px;
          margin-right: 10px;
        }
      }

      .magnet-text {
        display: block;
        font-weight: $bold;
        text-transform: uppercase;
      }
    }
  }

  .products {

    .title {
      text-align: center;
      margin: 0 0 30px;
    }

    .categories-list {
      position: relative;
      z-index: 2;
    }

    @include respond-down(small){
      .product-card__item{
        &:nth-child(1n+4){
          display: none;
        }
      }
    }


    .magnet-bg {
      display: block;
      position: absolute;
      width: 313px;
      height: 598px;
      top: 2000px;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../images/Main/about/magnet-bg.png');
      z-index: 1;
      @include respond-down(middle) {
        display: none;
      }
    }
  }

  .management-grid {
    display: grid;
    grid-auto-rows: 400px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px 20px;

    &.smalled {
      grid-template-columns: 1fr 1fr 1fr;
      @include respond-down(middle) {
        grid-auto-rows: 400px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4px 4px;
      }
      @include respond-down(small) {
        grid-template-columns: 1fr;
        grid-gap: 20px 20px;
        grid-auto-rows: 400px;
      }
    }

    @include respond-down(middle) {
      grid-auto-rows: 400px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 4px 4px;
    }
    @include respond-down(small) {
      grid-template-columns: 50% 50%;
      grid-auto-rows: 250px;

    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;
    }

    &__image-holder {
      width: 170px;
      height: 170px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      background-size: cover;
      margin-bottom: 27px;
    }

    &__name {
      font-weight: bold;
      font-size: 21px;
      text-align: center;
      margin-bottom: 15px;
    }

    &__post {
      font-size: 16px;
      color: #535353;
      margin-bottom: 17px;
    }

    &__contact {
      font-size: 18px;
      color: #4A90E2;
      text-decoration: underline;
    }
  }

  .profits {

    .title {
      margin: 0 0 40px;
      font-weight: $bold;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      text-transform: uppercase;
      color: $black;
    }

    .pros {
      display: flex;
      justify-content: space-between;
      @include respond-down(middle) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      @include respond(small) {
        display: block;
      }

      .pros-item {

        @include respond-down(middle) {
          &:nth-child(n+4) {
            margin-top: 45px;
          }
        }

        @include respond(small) {
          margin-bottom: 25px;
          font-size: 0px;
          &:last-child{
            margin-bottom: 0px;
          }
          &:after{
            display: table;
            width: 100%;
            clear: both;
            content: '';
          }
        }

        .icon {
          text-align: center;
          width: 90px;
          @include respond-up(small){
            height: 70px;
            display: flex;
            img{
              object-fit: contain;
            }
          }


          @include respond(small) {
            width: 50px;
            display: inline-block;
            height: auto;
            vertical-align: middle;
          }
        }

        .text {
          max-width: 227px;
          margin: 22px 0 0;
          font-weight: $bold;
          font-size: 16px;
          line-height: 19px;
          color: $black;
          @include respond(small) {
            display: inline-block;
            width: calc(100% - 70px);
            margin-left: 20px;
            max-width: 100%;
            font-size: 14px;
            margin-top: 0px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .respect {
  }

  .partners {
  }

  .all-link{
    text-align: center;
    padding: 0 69px;
    margin-top: 20px;

    @include respond-down(small){
      margin-bottom: 30px;
      padding: 0 20px;
    }

    .button{
      height: 52px;
      line-height: 50px;
      bottom: 34px;
      left: 39px;
      right: 39px;
      font-weight: 500;
      z-index: 2;
      white-space: nowrap;
      width: 100%;
      max-width: 300px;

      @include respond-down(small){
        max-width: 100%;
      }

    }
  }
}

