.review-page{
  display: flex;
  margin-bottom: 40px;

  @include respond-down(medium){
    display: block;
  }

  .reviews__items{
    margin-right: 20px;
    display: block;
    flex: 1 1 70%;

    @include respond-down(middle){
      flex: 1 1 65%;
    }

    @include respond-down(medium){
      width: 100%;
    }

    @include respond(small){
      margin-bottom: 20px;
    }
    .reviews__item{
      display: flex;
      margin-bottom: 20px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;

      @include respond-down(middle){
        align-items: center;
      }

      @include respond-down(small){
        display: block;
      }

      .left-holder{
        flex: 1 1 25%;
        position: relative;

        @include respond-down(small){
          flex: 1 1 100%;
        }

        .reviews__image{
          position: relative;
          display: block;

          @include respond-down(small){
            padding: 30px 0px 0px 0px;
          }

          .review-image{
            filter: blur(2px);
            @include respond-down(small){
              display: none;
            }
          }
        }
        .preview-holder {
          text-align: center;
        }

        .logo-holder-company{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

          @include respond-down(small){
            position: static;
            transform: none;
            display: inline-block;
          }
          img{
            max-height: 100px;
            width: auto;

            @include respond-down(small){
              position: static;
            }
          }
        }
      }
      .right-holder{
        flex: 1 1 75%;
        padding: 20px 25px;

        @include respond-down(small){
          flex: 1 1 100%;
        }

        .reviews__title{
          margin: 0px 0px 20px 0px;
          height: auto;
          padding: 0px;
        }

        .review-text{
          font-size: 14px;
          text-align: justify;

          @include respond-down(middle){
            font-size: 13px;
          }

          @include respond-down(small){
            text-align: left;
          }
        }

        .button-holder{
          margin-top: 20px;
          @include respond-down(small){
            text-align: center;
          }
          .button{
            padding: 12px 13px;
            font-size: 14px;
            text-transform: none;
          }
        }
      }
    }
  }
  .form-holder{
    flex: 1 1 30%;
    position: relative;

    @include respond-down(middle){
      flex: 1 1 30%;
    }

    @include respond-down(medium){
      flex: 1 1 100%;
    }

    .review-form-wrapper{
      position: sticky;
      top: calc(#{$headerHeight} + 20px);
      padding: 12px 20px;

      .form-row{
        flex-direction: column;
        margin: 0px;
        .form-input{
          width: 100%;
          margin: 0px 0px 20px 0px;
          padding: 0px;

          .form-field{
            &.file{

              label{
                background-position: 100% 50%;

                @include respond-down(middle){
                  padding-left: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
