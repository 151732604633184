&.tests {
  position: relative;
  height: 715px;
  overflow: hidden;

  @include respond-down(middle) {
    height: initial;
  }

  .block.title {
    margin: 0 0 18px 0;
    @include respond-down(middle) {
      margin: 0 0 -3px 0;
    }
  }

  .block.content {
    position: relative;

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .column-list {
      display: flex;
      flex-direction: column;
    }

    .column-nav {
      display: flex;
      position: relative;
      justify-content: flex-end;
    }
  }

  .slick-slide > div {
    font-size: 0;
  }
}

.tests__videos {
  position: absolute;
  left: 0;
  top: 10px;
  right: calc(50% - 225px);


  @media all and (max-width: 1280px ) and (min-width: 1000px) {
    right: 0;
    width: 100%;
    padding-right: 450px;

  }

  @include respond-down(middle) {
    position: static;
    padding-right: 0px;
  }

  @include respond-down(large){
  }

  @include respond-down(small) {
    width: 100%;
  }
}

.tests__video-active {

  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include respond-down(middle) {
    width: initial !important;
  }

  @include respond-down(small) {
    display: flex;
    width: 100%;
    position: relative;
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }

    &.active {
      display: flex !important;
    }
  }

  & > a:first-child {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.tests__video-image {
  width: 100%;
  max-height: 594px;
  object-fit: cover;
  @include respond-down(middle) {
    max-height: 416px;
  }
  @include respond-down(medium) {
    max-height: 360px;
  }
  @include respond-down(small) {
    max-height: 240px;
  }
}


.mobile-test-firs{
  position: relative;
}
.tests__play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
  @include respond-down(middle) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  @include respond-down(small) {
    transform: translateX(-50%) translateY(-50%) !important;
    right: auto !important;
  }

  .svg-icon {
    transition: transform ease .1s;
  }

  &:hover {
    .svg-icon {
      transform: scale(1.06);
    }
  }

  &:active {
    .svg-icon {
      transform: scale(1.08);
    }
  }

  @include respond-down(middle) {
    right: 50%;
    transform: translateX(50%) translateY(-50%);
  }
}

.tests__video-title {
  padding: 17px 25px;
  width: 100%;
  background: $white;
  text-align: right;
  font-weight: $semi;
  font-size: 18px;
  color: $black;
  display: block;
  line-height: 15px;
  @include respond-down(middle) {
    text-align: center;
  }
  @include respond-down(small) {
    padding: 21px 13px;
    font-size: 14px;
    line-height: 15px;
  }
}


.tests__right-block {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 350px;
  justify-content: space-between;
  background: $greyec;
  z-index: 10;
  max-height: 663px;
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond-down(middle) {
    max-height: 490px;
  }
  @include respond-down(medium) {
    max-height: 413px;
  }
  @include respond-down(small) {
    max-height: 170px;
  }

  @include respond-down(middle) {
    margin: 17px 0 0 0;
    background: $t;
  }

  @at-root &_gradient {
    display: block;
    height: 83px;
    width: 100%;
    position: absolute;
    bottom: -10px;
    left: 0px;
    right: 0;
    background: transparent url("../svg/mask-shadow.svg") 0 0;
    background-size: cover;
    z-index: 10;
    @include respond-down(middle){
      bottom: 0;
      left: 0px;
    }
  }
  .simplebar-content-wrapper{
    position: static;
  }
}


.tests__previews {
  max-width: 300px;


}

.tests__preview {
  display: flex;
  margin: 8px 0;
  cursor: pointer;
  flex-direction: column;
  &:last-child{
    margin-bottom: 30px;
  }

  .text{
    font-size: 13px;
    padding: 15px 12px;
    background: #ffffff;
  }
  //@include respond-down(middle) {
  //  margin: 2px 0;
  //  max-height: 75px;
  //}
}

.tests__preview-image {
  width: 100%;
  object-fit: cover;
}

.tests__progress {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  min-height: 100%;
  width: 5px;
  z-index: 5;
  background: #D8D8D8;
  border-radius: 40px;

  & > * {
    cursor: pointer;
  }

  .slick-dots {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    li {
      opacity: 0;
    }
  }

  &-slider {

    &:before {
      content: "";
      position: absolute;

      background: #A1A1A1;
    }
  }
}

.tests__bg-image {
  display: block;
  width: 313px;
  height: 598px;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url('../images/Main/index/tests__bg-image.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}


.simplebar-content {

}

.simplebar-scroll-content {

}
