&.additional {

  .additional__items {
    display: flex;
    width: 100%;
    height: 360px;
    justify-content: space-between;

    @include respond-down(small) {
      height: initial;
      display: block;
    }
  }

  .additional__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    flex: 0 0 calc(50% - 15px);
    background: $white;
    position: relative;
    &:before{
      transition: all 0.5s;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid $orange;
      content: '';
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      &:before{
        opacity: 1;
        visibility: visible;
        transform: rotate(0deg);
      }
      .additional__title {
        color: $blue;
        &:after{
          opacity: 1;
          visibility: visible;
          transform: none;
        }
      }
    }
    @include respond-down(small) {
      flex: initial;
      height: 263px;
      &:not(:last-child){
        margin: 0 0 20px 0;
      }
    }

    @include respond-down(medium){
        .additional__icon{
          .image-icon {
            &.replace {
              width: 200px;
            }
            &.repair {
              width: 100px;
            }
          }
        }
    }


  }



  .additional__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 135px;
    color: $orange;






    @include respond-down(small){
      height: auto;

    }
  }

  .additional__title {
    margin: 60px 0 0 0;
    font-weight: $bold;
    font-size: 21px;
    color: $black;
    text-align: center;
    line-height: 24px;
    &:after{
      transition: $default-transition;
      display: block;
      height: 1px;
      width: 100%;
      background: $blue;
      content: '';
      opacity: 0;
      visibility: hidden;
      transform: translateY(5px);
    }

    @include respond-down(small){
      margin: 25px 0 0 0;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
