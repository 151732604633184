
.down-load-item {
  display: flex;
  padding: 26px 31px 52px 18px;
  background: #FFFFFF;
  justify-content: space-between;
  margin-bottom: 50px;

  @include respond-down(small) {
    margin-bottom: 20px;
  }

  @include respond-down(middle) {
    padding: 26px 12px 30px 12px;
  }

  @include respond-down(small) {
    flex-direction: column;
  }

  .left-cover {
    flex: 0 0 300px;
    margin-right: 40px;


    @include respond-down(middle) {
      margin-right: 20px;
      flex: 0 0 250px;
    }

    @include respond-down(small) {
      margin: 0px;
      flex: 1 1 auto;
    }

    .image-wrapper {
      display: flex;
      max-width: 300px;
      max-height: 420px;
      position: relative;

      .icon-holder {
        position: absolute;
        top: 10px;
        left: 10px;
        @include respond-down(small){
          top: calc(45px + 33px);
        }
      }

      @include respond-down(middle) {
        max-width: 250px;
        max-height: 332px;
      }

      @include respond-down(small) {
        width: 100%;
        position: relative;
        max-width: 100%;
        max-height: none;
        flex-wrap: wrap;
        display: block;
      }

      .holder-name {
        @include respond-down(small) {
          z-index: 11;
          left: 0;
          right: 0;
          order: -1;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
          margin: 20px 0px;
          text-align: left;
          color: black;
        }
      }

      img {
        object-fit: cover;
        object-position: 50% 50%;
        @include respond-up(small) {
          border: 2px solid #d8d8d840;
        }
      }
    }
  }

  .right-holder {
    flex: 1 1 auto;
    max-width: 70%;

    @include respond-down(medium) {
      max-width: 60%;
    }

    @include respond-down(small) {
      width: 100%;
      max-width: 100%;
    }

    .top-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond-down(small) {
        display: block;
      }

      .left-name {
        h2 {
          font-weight: bold;
          font-size: 16px;
          @include respond-down(small){
            text-transform: uppercase;
          }
        }

        .subtitle-holder {
          margin-top: 15px;
        }

      }

      .subtitle-holder {
        display: flex;
        margin-right: 45px;
        align-items: center;
        @include respond-down(middle) {
          margin-right: 20px;
        }

        @include respond-down(small) {
          .icon {
            .svg-icon{
              width: calc(45px * 0.8);
              height: calc(54px * 0.8);
            }
          }
        }

        .subtitle-block {
          margin-left: 35px;

          @include respond-down(middle) {
            margin-left: 15px;
          }

          .subtitle-value {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 23px;

          }

          .ext-size {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 23px;

            @include respond-up(small) {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }
      }

      .right-download {
        display: flex;
        align-items: center;

        @include respond-down(small) {
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
        }


        .button {
          padding: 17px 48px 18px 48px;

          @include respond-down(small) {
            padding: 12px 20px 12px 20px;

          }
        }
      }
    }

    .images-holder {
      @include respond-down(small) {
        display: none;
      }

      .images {
        border: 3px solid #F6AF43;
        margin-top: 30px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding: 13px 27px 16px 27px;
        background: #D8D8D8;

        .image-holder {
          display: inline;
          margin: 0px 32px;

          @include respond-down(middle) {
            max-width: 130px;
            max-height: 182px;
            margin: 0px 20px;
          }

          &:first-child {
            margin-left: 0px;
          }

          &:last-child{
            margin-right: 0px;
          }
        }
      }

      .total {
        text-align: center;
        font-size: 18px;
        color: black;
        margin-top: 23px;
      }
    }
  }


  &.compability {
    width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;

    @include respond-down(small){
      overflow: hidden;
    }

    @include respond-down(small){
      padding: 26px 18px 26px 18px;
    }
    .left-cover {
      flex: 0 0 320px;
      .image-wrapper {
        max-width: 320px;
        max-height: none;
        position: relative;
        @include respond-down(small){
          max-width: none;
          text-align: center;
          flex-direction: column;
          padding: 0px 0px 23px 0px;
        }
        &:after{
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          background: transparent url("../svg/lines-bordered-vertical.svg") 0 0 repeat-x;
          content: '';
          width: 10px;
          @include respond-down(small){
            top: auto;
            right: -30px;
            left: -30px;
            width: auto;
            height: 10px;
            background: transparent url("../svg/lines-bordered.svg") 0 0 repeat-x;

          }
        }
        img{
          border: none;
          @include respond-down(small){
            max-width: 200px;
            margin: 0 auto;
          }
        }
      }
    }

    .right-holder{
      padding-top: 26px;
      .images-holder{
        .images{
          margin-top: 0px;
        }
      }

      @include respond-down(small){
        display: flex;
        flex-wrap: wrap;

        .top-line{
          width: 100% ;
        }
        .images-holder{
          width: 100%;
          order: -1;
          margin-top: 0px;
          .wrapper-images{
            display: none;
          }

          .wrapper-logos{
            display: flex;
            flex-wrap: wrap;
            .total{
              order: -1;
              text-align: center;
              width: 100%;
              margin-top: 0px;
              margin-bottom: 20px;
            }
            .logos-holder{
              width: 100%;
              li{
                margin-bottom: 12px;
                padding: 0 15px;
                i{
                  max-width: 150px;
                }

                &:last-child{
                  i{
                    max-width: 75px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .images-holder{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      align-items: flex-end;

      .wrapper-logos{
        flex: 1 1 auto;
        max-width: 550px;

        .logos-holder{
          text-align: center;
          flex-wrap: wrap;
          display: flex;
          justify-content: center;
          align-items: center;
          li{
            width: 50%;
            margin-bottom: 45px;

            &:last-child{
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
