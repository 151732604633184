.contacts-page {
  
  .black {
    color:$black;
  }
  .contacts-main-block.vcard {
    .item {
      margin: -10px -12px 0 -12px;
      @media(min-width:784px) {
        margin: -20px -30px 0px -30px;
      }
    }
  }
  .contacts-main-block,
  .contacts-block {
    .item {
      .name {
        font-size: 16px;
        font-weight: $bold;
        text-align: center;
        margin-bottom: 20px;
        z-index: 1;
        background: $orange;
        padding: 20px 5px;



        @include respond-down(medium) {
          font-size: 13px;
        }
      }
    }
  }
  .contacts-block {
    margin-top: 60px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit,  minmax(340px, 1fr));
    &.flex-2 {
      display: block;
      @media(min-width:784px) {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.en{
      margin-top: 0px;
    }
    @include respond-down(small) {
      grid-template-columns: 1fr;
      margin: 60px 0 0;

      &.en{
        margin: 0px;
      }
    }

    .item {

      .item-wrapper{
        box-shadow: 0 0 0 1px rgba(245, 177, 66, .36);
        height: 100%;
        background: #ffffff;
      }


      .title {
        font-size: 20px;
        font-weight: $bold;
        text-align: center;
        margin-bottom: 20px;
      }

      .wrapper-contact {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 0px 10px;

        @include respond-down(medium) {
          padding: 0px 20px 0px 20px;
        }

        @include respond(small) {
          padding: 10px 20px 0px 20px;
        }
        .contact-line {
          display: flex;
          margin-bottom: 15px;
          .icon {
            .svg-icon {
              width: 21px;
              height: 20px;
            }
          }
          .value {
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }

      &_large {
        grid-row: span 2;
        .name_duplicate {
          background: $white;
          padding: 0 5px;
        }
        .item-wrapper {
          display: flex;
          flex-direction: column;
        }
        .wrapper-contact {
          flex: 1 1;
        }
      }
    }
  }

  .block-map {
    position: relative;
  }
  .map-container {
    padding-top: 0px;
  }
  .map {
    height: 425px;
  }

  .consult-form-product {
    margin-top: 50px;

    @include respond-down(small){
      margin-top: 20px;
    }
  }

  .services-maps-container {
    margin-top: 50px;

    @include respond-down(small){
      margin-top: 20px;
    }

    .tabs-heading-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @include respond-down(small) {
        flex-direction: column;
      }

      .heading-container {
        flex-grow: 1;
      }

      .tabs-holder {
        .tabs {
          display: flex;
          flex-wrap: nowrap;

          @include respond-down(small) {
            display: inline-block;
            font-size: 0px;
            white-space: nowrap;
          }

          .tab-holder {
            cursor: pointer;
            padding: 8px 0px 9px 0px;
            width: 150px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            background: transparent;
            border: 1px solid #{$blue};
            color: $blue;

            @include respond-down(small) {
              display: inline-block;
            }

            &.active {
              background: $blue;
              color: #ffffff;

            }
          }
        }
      }
    }
  }

  .tabs-container {
    position: relative;

    .tab-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      pointer-events: none;
      height: 0px;
      overflow: hidden;

      &.active {
        position: static;
        opacity: 1;
        visibility: visible;
        z-index: 0;
        pointer-events: auto;
        height: auto;
      }
    }
  }

  .list-table-container {
    margin-bottom: 40px;
    .tabs-table-container {
      display: flex;
      justify-content: space-between;

      .tab-holder {
        text-align: center;
        font-size: 17px;
        font-family: $h-font;
        text-transform: uppercase;
        background: $blue;
        color: #C3C8D7;
        flex: 1 1 auto;
        width: 50%;
        padding: 15px 0px;
        cursor: pointer;

        &.active {
          background: #EDD2B3;
          color: $blue;
        }

      }

    }
  }

  .tabs-containers-list {
    .contacts-table {
      width: 100%;
      table-layout: fixed;
      tr {
        &:first-child {
          td {
            font-size: 14px;
            color: #0E1733;
            font-weight: bold;
            border-bottom: 1px solid $orange;
          }
        }


        @include respond-down(small) {
          td {
            border-bottom: 1px solid #0e173338;
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }

      td {
        padding: 15px 30px;
        background-color: #ffffff;
        font-size: 14px;

        @include respond-down(medium) {
          font-size: 14px;
          padding: 15px 20px;
        }

        @include respond-down(small) {
          padding: 15px 10px;
        }

        &.city {
          white-space: nowrap;
          @include respond-down(medium) {
            white-space: normal;

          }
        }
        &:first-child{
          white-space: nowrap;
        }
        .city-name {
          color: $blue;
          font-family: $h-font;

          @include respond-down(medium) {
            font-size: 16px;
          }

          @include respond-down(small) {
            font-weight: 500;
            text-align: center;
          }
        }

        .phone {
          white-space: nowrap;
        }

        @include respond-down(small) {
          .item-value {
            margin-bottom: 20px;
            &:after {
              content: '';
              clear: both;
              display: table;
              width: 100%;
            }

            .icon-value {
              display: inline-block;
              vertical-align: middle;
              .svg-icon {
                width: 25px;
                height: 25px;
                background-size: contain;
              }
            }
            .value {
              display: inline-block;
              vertical-align: middle;
              max-width: calc(100% - 40px);
              margin-left: 10px;
              font-size: 15px;
              white-space: normal;
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

      }
    }
  }

  .requisites {
    margin-top: 40px;
    margin-bottom: 40px;


    .contacts-block {
      margin-top: 30px;

      .file{
        max-width: 80%;
        margin: 20px auto;
      }
      .item{
        margin: 0px;
      }
      &.flex-3 {
        .item {
          flex: 1 1 33.3333%;
          width: 33.3333%;
          margin: 0px;
        }

        @include respond-down(middle){
          flex-wrap: wrap;
          .item {
            flex: 1 1 50%;
          }
        }

        @include respond-down(small){
          .item {
            width: 100%;
          }
        }

      }
    }


    .item {
      padding: 0 20px;


      @include respond-down(middle) {
        padding-bottom: 40px;
      }
      @include respond-down(small){
        padding: 0px 0px 40px 0px;
      }


      .skew{
        transform: none;
        background: #ffffff;
        padding-bottom: 20px;
        height: 100%;

        .skew-wrapper{
          transform: none;
        }
      }

      .title{
        font-size: 17px;
      }

      @include respond-down(small){
        max-width: 100%;
      }
      .wrapper-contact {
        padding: 20px 15px 0px 15px;
        display: block;

        p{
          line-height: 1.4;
          font-size: 15px;
          overflow-wrap: break-word;

          @include respond-down(medium){
            font-size: 14px;
          }

        }
        .contact-line{
          .value{
            margin-left: 0px;
          }
        }

      }

      @include respond-down(small) {
        max-width: none;
      }
    }
  }

  .contacts-main-container{
    width: 100%;
    pointer-events: none;
    @media(min-width:784px) {
      position: absolute;
      top: 0;
    }

    z-index: 1;
    .contacts-main-block{
      background-color: $white;
      padding: 20px 30px 15px 30px;
      margin: 20px 20px 20px 0px;
      pointer-events: auto;

      @include respond-down(small){
        margin-right: 0;
        padding: 10px 12px;
      }

      h2{
        color: $black;
        letter-spacing: 0;
        line-height: 15px;
        margin: 5px 0px 30px 0px;
      }

      .address{
        color: $black;
        letter-spacing: 0;
        line-height: 19px;
        margin: 20px 0px;

      }

      .text{
        color: #767676;
        letter-spacing: 0;
        line-height: 18px;
        margin: 10px 0px 15px 0px;
        a{
          color: $black;
        }
        .icon-holder{
          display: inline-block;
          width: 30px;
          @include respond-down(small){
            width: 25px;
          }
        }

        .value{
          color: $black;
          margin-left: 10px;
        }

        .icon-clock {
          width: 14px;
          height: 14px;
        }

        .value-inline-block {
          display: inline-block;
          position: relative;

          .value-block {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;

            span {
              display: block;
            }
          }
        }
      }

      .text-center{
        text-align: center;
      }

      .button{
        margin: 20px auto;

      }

      .social-footer__box {
        justify-content: center;
      }

      &__button {
        text-align: center;
        a {
          width: 100%;
        }
      }
    }
  }



}


