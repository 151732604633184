&.hero {
  display: block;
  position: relative;
  overflow: hidden;
  height: 608px;
  width: 100%;
  @include respond-up(xl) {
    margin-top: #{$headerHeight};
  }
  @include respond-down(large) {
    margin-top: 75px;
  }

  @include respond-down(large) {
    height: 500px;
  }
  @include respond-down(middle) {
    height: 395px;

  }
  @include respond-down(small) {
    height: 390px;
  }

  .hero__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;

    @include respond-down(small) {
      width: initial;
    }

  }
  .hero__info {
    color: white;
  }
  .hero__bg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;

    @include respond-down(medium) {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($blue, 0.58);
    }
  }

  .hero__text {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    z-index: 2;
    &:after{
      display: none;
    }
    @include respond-up(xl) {
      padding: 0 0 0 50px;

    }
    @include respond-down(small) {
      justify-content: center;
      align-items: center;
    }
  }

  .hero__title {
    font-size: 60px;
    font-weight: $extra;
    text-transform: uppercase;

    @include respond-down(middle) {
      transform: translateY(-15px);
      font-size: 48px;
    }
    @include respond-down(small) {
      font-size: 36px;
      margin-top: 79px;
      position: relative;

      &:after{
        content: '';
        height: 2px;
        max-width: 90%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 50px;
        background: #f5b142;
        position: absolute;
        bottom: 0px;
      }
    }
  }

  .hero__description {
    position: relative;
    margin: 13px 0 0 0;
    font-size: 28px;
    line-height: calc(48 / 36);

    @include respond-down(middle) {
      font-size: 26px;
      line-height: 36px;
    }
    @include respond-down(small) {
      font-size: 21px;
      text-align: center;
      line-height: 27px;
      transform: translateY(-20px);
    }
  }

  .hero_text{
    max-width: 545px;
    font-size: 14px;
    color: #fff;
    margin-top: 20px;
  }

  .hero__line {
    display: block;
    position: absolute;
    top: 16px;
    left: -60px;
    width: 40px;
    height: 5px;
    background: $orange;
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 0% 100%);
    @include respond-down(middle) {
      top: 15px;
      width: 37px;
    }
  }

  // .hero__button {
  //   width: 195px;
  //   height: 52px;
  //   line-height: 50px;
  //   margin: 43px 0 0 0;
  //   transition: all ease .3s;
  //   border: 1px solid transparent;

  //   &:hover {
  //     background: $t;
  //     color: $white;
  //     border-color: $orange;
  //   }

  //   @include respond-down(middle) {
  //     transform: translateY(-13px);
  //     margin: 72px 0 0 0;
  //   }
  //   @include respond-down(small) {
  //     margin: 74px 0 0 0;
  //   }
  // }

}
