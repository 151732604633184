.heading-holder-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-down(middle){
    display: block;
    h1.left-after{
      &:after{
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


.tabs-lang-holder{
  display: flex;
  align-items: center;
  font-size: 15px;

  @include respond-down(middle){
    margin-top: 80px;
    justify-content: flex-end;
  }
  @include respond-down(medium){
    margin-top: 50px;
    font-size: 13px;
  }

  @include respond-down(small){
    .title-select{
      display: none;
    }
  }

  .list-lang{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include respond-down(small){
      margin: 0 auto;
    }
    li{
      margin-left: 20px;
      position: relative;
      cursor: pointer;
      @include respond-down(large){
        margin-left: 10px;
      }
      @include respond-down(small){
        &:first-child{
          margin-left: 0px;
        }
      }
      &:after{
        content: '';
        width: 22px;
        height: 32px;
        position: absolute;
        top: 0;
        transform: translateY(calc(-100% - 40px)) translateX(-50%);
        left: 50%;
        background: transparent url("../svg/download-arrow.svg") 50% 50% no-repeat;
        background-size: cover;
        opacity: 0;
        transition: $default-transition;
        @include respond-down(medium){
          transform: translateY(calc(-100% - 20px)) translateX(-50%);
          zoom: 0.7;
        }
        @include respond-down(small){
          transform: translateY(calc(-100% - 20px)) translateX(-50%);
          zoom: 0.5;
        }
      }

      @include respond-down(small){
        &:before{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 2px;
          border: 2px solid $orange;
          content: '';
          opacity: 0;
          transition: $default-transition;
        }
      }
      &.active{
        &:after{
          opacity: 1;
          transform: translateY(calc(-100% - 20px)) translateX(-50%);
          @include respond-down(medium){
            transform: translateY(calc(-100% - 10px)) translateX(-50%);
            zoom: 0.7;
          }
          @include respond-down(small){
            transform: translateY(calc(-100% - 10px)) translateX(-50%);
            zoom: 0.5;
          }
        }

        &:before{
          opacity: 1;
        }
      }
    }
  }
}

.with-icons-holder{
  position: relative;
  .holder-catalog-group{
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
    left: 0;
    right: 0;
    &.active{
      position: static;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: $default-transition;
    }
  }
}

.down-load-groups{

  @include respond-down(small){
    margin-top: 50px;

    .heading-container{
      text-align: center;
      margin-bottom: 50px;
      h2.styled-header{
        text-transform: uppercase;
        &:after{
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }
  }
  h2{
    font-weight: bold;
  }

  .tiles-wrapper{
    display: flex;
    margin: 0px -10px 50px -10px;
    flex-wrap: wrap;

    @include respond-down(small){
      margin-bottom: 20px;
    }



    .download-tile{
      flex: 0 0 25%;
      width: 25%;
      padding: 0 10px 20px 10px;

      .download-tile-wrapper{
        background: #FFFFFF;
        .image{
          img{
            width: 100%;
          }
        }
      }


      @include respond-down(middle){
        width: 33.33333%;
        flex: 0 0 33.33333%;
      }

      @include respond-down(small){
        width: 100%;
        flex: 0 0 100%;
      }

      .bottom-info{
        padding: 30px 11px 16px 15px;

        .title{
          font-weight: bold;
          font-size: 20px;
        }



        .info-holder{
          display: flex;
          margin-top: 20px;

          .subtitle-value{
            font-size: 16px;
            text-transform: uppercase;
          }
          .ext-size{
            font-weight: bold;
          }
          .icon{
            margin-right: 20px;
          }
        }
        .button-holder{
          text-align: right;
          margin-top: 10px;
          .button{
            padding: 12px 39px;
          }
        }
      }
    }
  }
}
