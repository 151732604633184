.index-block.reviews {
  .block.title {
    @include respond-down(middle) {
      margin: 0 auto 19px !important;
    }
  }

  .block.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.reviews__items {
  display: grid;
  grid-auto-rows: 520px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px 20px;

  @include respond(middle) {
    grid-auto-rows: 600px;
    grid-gap: 15px 15px;
  }
  @include respond-down(medium) {
    grid-template-columns: 1fr 1fr;
  }
  @include respond-down(small) {
    display: block;
  }
}

.reviews__item {
  display: none;
  flex: 0 0 33%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $white;

  &.active {
    display: flex;
  }

}

@include respond-down(medium) {
  .reviews__item.active + .reviews__item.active + .reviews__item.active {
    display: none;
  }
}

.reviews__image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: inherit;
  }

  @include respond-down(small) {
    margin: 0 auto;
    width: auto;
    justify-content: flex-start;
    overflow: hidden;
    height: auto;

  }


}

.reviews__text {
  margin: 16px 0 0 0;
  font-size: 16px;
  color: $grey76;
  max-height: 120px;
  overflow: hidden;

}

.reviews__title {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  font-weight: $bold;
  font-size: 16px;
  line-height: 20px;
  color: $black;
  text-transform: uppercase;
  padding: 0 20px;

  .svg-icon {
    flex: 0 0 31px;
    margin: 0 17px 0 0;
  }

  @include respond(small){
    font-size: 14px;
    padding-bottom: 20px;
    height: 60px;
    overflow: hidden;
  }
}

.reviews__more {
  display: inline-flex;
  margin: 24px auto 0;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-weight: $bold;
  text-transform: uppercase;
  white-space: nowrap;

  span {
    @include textUnderline;
  }

  .svg-icon {
    flex: 0 0 31px;
    margin: 0 16px 0 0;
  }
}

.reviews__button-all {
  width: 224px;
  height: 52px;
  margin: 24px auto 0;
}

.fancybox-container {
  .reviews__item {
    .reviews__title {

    }
  }
}
