&.profit {
  position: relative;

  @include respond-down(middle) {
    height: initial;
  }
  @include respond(small) {
  }

  .row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    @include respond-down(middle) {
      display: block;
    }
  }

  .profit__title-block {
    @include respond-up(large) {
      margin: -16px 0 0 -10px;
    }
    @include respond-down(large) {
      margin: -16px 0 0 25px;
    }
    @include respond-down(small){
      margin: 0px;
    }
  }

  .profit__image-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 43.9vw;
    height: 500px;
    @include respond-down(middle) {
      display: flex;
      position: static;
      margin-top: 30px;
      height: 290px;
      width: 60%;
    }
    @include respond-down(small) {
      height: initial;
      margin-left: initial;
      margin-top: initial;
      width: 100%;
    }
  }

  .profit__image-text {
    display: flex;
    position: absolute;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    max-width: 320px;
    right: 0px;
    //background: rgba(0,0,0, 0.4);
    padding: 20px 30px 20px 25px;
    top: 80px;
    bottom: 0px;
    @include respond-down(large){
      max-width: 280px;
      padding-right: 0px;
    }
    @include respond-down(middle) {
      position: static;
      max-width: 223px;
      margin-left: 40px;
      @include text();
      color: $black;
      margin-top: 14px;
      font-size: 16px !important;
      line-height: 1.2 !important;
      background: transparent;
      padding: 0px;
    }
    @include respond-down(small) {
      max-width: initial;
      margin-left: initial;
      margin-top: 0;
    }

    &_title {
      position: relative;
      margin: 0 0 24px 0;
      font-weight: $dem;
      @include respond-down(middle) {
        margin: 0 0 27px 0;
      }
      @include respond-down(small) {
        display: flex;
        margin: 30px 0 23px 0;
      }

      .svg-icon {
        position: absolute;
        top: 0;
        left: -50px;
        transform: translateX(-50%);
        @include respond-down(small) {
          position: static;
          flex: 0 0 46px;
          transform: none;
          margin: 0 20px 0 0;
        }
      }
    }

    &_description {
      @include respond-down(small) {
        font-size: 14px;
      }
    }

    &_button {
      width: 223px;
      height: 52px;
      line-height: 52px;
      transition: $default-transition;
      transform: translateY(50%);
      border: 1px solid transparent;
      @include respond-down(small) {
        margin: 23px auto 0;
        transform: none;
      }
      &:hover{
        background: $blue;
        border-color: $blue;
        color: #ffffff;
      }
    }
  }

  .profit__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 75% 0;

    @include respond-down(small) {
      display: none;
    }
  }

  .profit__title {
    font-weight: $dem;
    font-size: 60px;
    color: #000000;

    @include respond-down(middle) {
      font-size: 48px;
      text-align: center;
    }
    @include respond-down(small) {
      font-size: 30px;
    }

  }

  .profit__pros {
    display: flex;
    width: 100%;
    margin: 45px 0 0 0;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond-up(middle) {
      margin: 31px 0 0 0;
      padding-left: 30px;
    }
    @include respond-down(small) {
      margin: 10px 0 0 0;
      flex-direction: column;
    }
  }

  .profit__pros-item {
    display: flex;
    max-width: 310px;
    height: 150px;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    text-align: left;

    @include respond-down(middle) {
      flex-direction: row;
      height: 120px;
    }
    @include respond-down(small) {
      height: initial;
      align-items: flex-start;
      max-width: initial;
      margin: 27px 0 0 0;
      display: block;
    }

    &:nth-child(n+3) {
      @include respond-up(large) {
        margin: 40px 0 0 0;
      }
    }

    @include respond-down(middle) {
      max-width: 50%;
    }
    @include respond-down(small) {
      flex-direction: row;
      max-width: initial;
    }
  }

  .profit__pros-icon {
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    img{
      object-fit: cover;
      height: 100%;
      max-width: none;
    }
    @include respond-down(small) {
      display: block;
      float: left;
      margin: 0 20px 0 0;
      width: 70px;
      height: auto;

      img{
        height: auto;
        max-width: 100%;
      }
    }
  }

  .profit__pros-text {
    margin: 23px 0 0 0;
    font-weight: $bold;
    font-size: 16px;
    color: $black;
    @include respond-down(middle) {
      max-width: 378px;
      padding: 0 34px;
    }
    @include respond-down(small) {
      margin: 0;
      max-width: 100%;
      padding: 0;
    }

    @include respond(small){
      font-size: 14px;
      float: left;
      width: calc(100% - 90px);
    }
  }
}
