.download-catalog-form{
  &.success {
    .form-field, .icon-heading {
      display: none;
    }
  }
  .icon-heading{
    font-size: 20px;
    display: flex;
    align-items: center;

    .title{
      margin-left: 10px;
      display: inline-block;

      .description{
        margin: 5px 0px 0px 0px;
        font-size: 13px;
      }
    }


  }


}
