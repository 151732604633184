@import "./reviews";
@import "./news";
.index-block {
  @import "./hero";
  @import "./profit";
  @import "./products";
  @import "./_consult";
  @import "./_tests";
  @import "./partners";
  @import "./additional";

  @import "./advices";

  .sub-title{
    font-size: 20px;
    margin: 0 auto 46px;
    text-align: center;

    @include respond-down(middle){
      margin-bottom: 10px;
      font-size: 18px;
    }
    @include respond-down(small){
      font-size: 13px;
      margin-bottom: 30px;
    }
  }

  .block {
    &.title {
      display: flex;
      position: relative;
      justify-content: center;
      margin: 0 auto 20px;
      font-weight: $bold;
      font-size: 30px;
      line-height: 37px;
      color: $black;
      text-align: center;
      align-items: center;
      @extend .styled-header;
      @include respond-down(middle) {
        min-height: 55px;
        margin: 0 auto 10px;
        font-size: 24px;
        line-height: 29px;
      }
      @include respond-down(small) {
        margin: 0 auto 25px;
        font-size: 22px;
        line-height: 25px;
        text-align: center;
        justify-content: center;
        min-height: 0px;
      }

      .button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 173px;

        @include respond-down(middle) {
          height: 52px;
          width: 150px;
          line-height: 52px;
        }
        @include respond-down(small) {
          display: none;
        }
      }
    }

    .all-link{
      text-align: center;
      padding: 0 69px;
      margin-top: 20px;

      @include respond-down(small){
        padding: 0 20px;
      }

      .button{
        height: 52px;
        line-height: 50px;
        bottom: 34px;
        left: 39px;
        right: 39px;
        font-weight: 500;
        z-index: 2;
        white-space: nowrap;
        width: 100%;
        max-width: 300px;

        @include respond-down(small){
          max-width: 100%;
        }

      }
    }

  }


}
.replacement-banner{
  background: transparent url("../images/Main/replace/bg-replacement-banner.jpg") 50% 0% no-repeat;
  padding: 45px 30px 50px 45px;
  @include respond-down(small){
    padding: 35px 20px 40px 20px;
  }
  display: block;
  color: #fff;
  background-size: cover;
  position: relative;
  transition: $default-transition;
  background-attachment: fixed;
  &:hover{
    &:before{
      background: rgba(0,0,0, 0.7);
    }
  }
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.55);
    content: '';
    bottom: 0;
    z-index: 0;
    transition: $default-transition;
  }
  span{
    display: block;
    z-index: 2;
    position: relative;
  }
  .title{
    @include respond-property(font-size, 24px, 24px, 30px, 40px, 40px, 42px);
    font-weight: 500;
    max-width: 800px;
    margin-bottom: 24px;
    text-transform: uppercase;
    @include respond-down(small){
      font-size: 20px;
    }
  }
  .description{
    @include respond-property(font-size, 16px, 16px, 18px, 24px, 24px, 24px);
    @include respond-property(line-height, 25px, 25px, 28px, 34px, 34px, 34px);
    font-size: 24px;
    line-height: 34px;
    max-width: 777px;
    font-weight: 500;
  }
  .button{
    width: 192px;
    height: 52px;
    line-height: 52px;
    margin-top: 50px;
    border: 1px solid transparent;
    transition: $default-transition;
    @include respond-down(small){
      width: 200px;
      margin: 30px auto 0px auto;
      font-size: 13px;
      height: 40px;
      line-height: 40px;
    }
    &:hover{
      background: transparent;
      border-color: $orange;
      color: #fff;
    }
  }
}

