.certificates-page {
  .page-title {
    margin: 0 0 50px;
  }
  .page-content {
    margin: 0 0 70px;

    .certificate {
      width: 76px;
      height: 108px;
      position: absolute;
      transform: rotateZ(15deg) translate(-50%, -50%);
      z-index: 1;
      top: 50%;
      right: 10px;

      @include respond-down(small){
        width: calc(76px/1.5);
        height: calc(108px/1.5);
      }
    }

    .product-card__item {
      cursor: default;
      pointer-events: none;

      .image-holder{
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-down(small){
          height: 180px;
        }
        img{
          display: flex;
          object-fit: contain;
          max-height: 90%;
          max-width: 90% ;
        }
      }
      &.vertical{
        background-size: auto 50%;
      }
      &.horizontal{
        background-size: 60% auto;
      }

      & > * {
        cursor: pointer;
        pointer-events: all;
      }
    }

    .categories-list {
      margin: 0 0 50px;
    }

    .paragraph {
      &, p {
        font-size: 16px;
        line-height: calc(19 / 16);
        color: #191A19;
      }
    }
  }
}
