&.consult {
  display: flex;
  padding: 28px 0 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $blue;
  @include respond-down(middle) {
    padding: 33px 0 64px;
  }
  @include respond-down(small) {
    padding: 36px 0 99px;
  }
  padding-left: 10px;
  padding-right: 10px;

  .svg-icon.icon-consult__grill, .svg-icon.consult__grill{
    margin-right: 1rem;
    @media(max-width:1349px) {
      display: none;
    }
  }

  .consult__title {
    color: $white !important;
    min-height: auto !important;
    margin: 0 auto 22px !important;
    @include respond-down(middle) {
      margin: 0 auto 33px !important;
    }
    @include respond-down(small) {
      max-width: 85%;
      margin: 0 auto 20px !important;
    }

    .icon{
      margin-right: 20px;
    }
  }

  .consult__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
  }

  .consult__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 25px 0 28px;
    justify-content: center;

    @include respond-down(middle) {
      padding: 0 10px;
    }
    @include respond-down(small) {
      padding: 0 20px;
    }
    &._four {
      justify-content: center;
      .icon-consult__grill {
        margin-right: 20px;
      }
      .consult__form-group {
        margin-top: 20px;
      }
    }
    .form-policy {
      display: flex;
      justify-content: center;
      label{
        width: auto;
      }
      &:not(.en) {
        @media(min-width:1260px) {
          max-width: 794px;

          width: 100%;
        }
      }
    }
  }

  .consult__text_girl{
    max-width: 380px;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 40px;

    @include respond-down(middle){
      display: none;
    }

    .consult__text{
      margin-left: 35px;
    }
  }

  .consult__text {
    font-weight: $bold;
    font-size: 16px;
    color: $white;
    text-transform: uppercase;
    line-height: 20px;
    @media(max-width:1259px) {
      display: none;
    }
  }

  .consult__form-group {
    display: flex;
    position: relative;
    margin-bottom: 1em;

    @media(max-width:1259px) {
      width: 100%;
      margin: 1em auto;
      justify-content: center;
    }
    .consult__input:not(input) {
      margin: 0px 0px 20px 0px;
    }
    @media (min-width:784px) {
      .consult__input:not(input) {
        margin: 0px 15px 0px 0px;
        &:last-child {
          margin: 0;
        }
      }
    }
    @include respond-down(small) {
      display: block;
    }
  }

  .success {
    .success-wrapper-holder {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }

  }

  .success-wrapper-holder {
    transition: $default-transition;
    position: absolute;
    top: 0;
    left: 0;
    right: 40px;
    bottom: 8px;
    background: rgba(255, 255, 255, 0.95);
    padding: 7px 10px;
    text-align: center;
    border-radius: 50px;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  .errors {
    margin: 4px 0 0px 15px;
  }

  input[type="text"].consult__input.country {
    background: $white;
  }

  input[type="text"].consult__input {
    background: $white;
    height: 52px;

    &.email-phone,
    &.name {

      @include respond-down(middle) {
        width: 100%;
      }

      // @include respond-down(small) {
      //   width: 100%;
      // }
    }

    &, &::placeholder {
      color: $grey7c;
      opacity: 1;
    }
  }

  .consult__button {
    width: 147px;
    height: 52px;
    line-height: 52px;
    margin: 0 0 0 32px;
    flex: 0 0 147px;
    font-weight: bold;

    &.no-margin{
      margin: 0px;
    }

    &:hover {
      color: $white;
    }

    @include respond-down(middle) {
      margin: 0;
    }
    @include respond-down(small) {
      margin: 0;
      width: 100%;
      height: 52px;
      flex: initial;
    }
  }

  .consult__policy {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;

    label{
      margin: 0 auto;
      display: inline-block;
    }
    @include respond-down(middle) {
      bottom: -28px;

      label {
        margin-left: 8px;
      }
    }
    @include respond-down(small) {
      bottom: -60px;
    }
  }


  .consult__check {
    @include checkboxStyleDark();
  }

  .consult__check-label {
    margin: 5px 0 0 12px;
    color: $lightblue;

    a {
      color: $orange;
      border-bottom: 1px solid currentColor;
      transition: all ease-out .2s;

      &:hover {
        border-bottom-color: $t;
      }
    }
  }
}
