&.products {

  .categories-list-tree-slick {
    display: block;height: 350px;

    &.slick-slider {
      height: 350px;

      @include respond-down(small){
        max-height: 260px ;
      }

      .slick-list {
        height: 100%;

        .slick-track {
          height: 100%;

          .slick-slide {
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;

            &>div {
              height: 100%;

              .grid-item-catalog {
                height: 100%;
              }
            }
          }
        }
      }

      .slick-arrow {
        &.slick-next {
          //background-image: url("../svg/arrow-prev.svg");
          //transform: translateY(-50%) rotateZ(180deg);
          right: -30px;

          @include respond(middle) {
            right: 30px;
          }

          @include respond-down(small){
            right: 30px;
          }
        }

        &.slick-prev {
          //background-image: url("../svg/arrow-prev.svg");
          //transform: translateY(-50%);
          left: -30px;

          @include respond(middle) {
            left: 30px;
          }

          @include respond-down(small){
            left: 30px;
          }
        }
      }

    }
  }

  &.en{
    @include respond-up(large){
      .products__grid{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-rows: 350px;


        .grid-item-catalog{

          &:nth-child(2){
            .product-card__item{
              .product-card__image-holder{
                img{
                  max-width: 100%;
                }
              }
            }
          }
        }

        .product-card__item{
          .product-card__image-holder{
            height: 80%;
            &.vertical{
              max-height: none;
              img{
                max-height: 250px;
              }
            }

            &.horizontal{
              max-height: none;
              max-width: none;
              img{
                max-height: 250px;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @include respond-up(large){
    .products__grid{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-auto-rows: 350px;



      .product-card__title{
        padding: 5px 10px;
        line-height: 1.1;
        text-transform: none;
      }
      .grid-item-catalog{
        border-top: 2px solid $blue;

        &:nth-child(2){
          .product-card__item{
            .product-card__image-holder{
              img{
                max-width: 170px;
              }
            }
          }
        }
      }
      .product-card__item{
        justify-content: space-between;
        &:not(.product){
          &:before{
            background: #012d72eb;
          }
        }



        .product-card__image-holder{
          display: flex;
          height: 80%;
          img{
            height: auto;
            width: auto;
          }
          &.vertical{
            max-height: none;
            img{
              max-height: 180px;
            }
          }

          &.horizontal{
            max-height: none;
            max-width: none;
            img{
              max-height: 160px;
              max-width: 100%;
            }
          }
        }
      }
    }

  }
  @include respond-up(middle){
    .grid-item-catalog{
      &:nth-child(1n+6){
        display: none;
      }
    }

    .categories-list-tree-slick .grid-item-catalog {
      &:nth-child(1n+6){
        display: block;
      }
    }
  }

  @include respond-down(middle){
    .grid-item-catalog{


      &:nth-child(1n+6){
        display: block;
      }

      &:nth-child(1n+7){
        display: none;
      }
    }

    .categories-list-tree-slick .grid-item-catalog {
      &:nth-child(1n+7){
        display: block;
      }
    }
  }

  @include respond-down(small){
    .grid-item-catalog{


      &:nth-child(1n+6){
        display: block;
      }

      &:nth-child(1n+7){
        display: block;
      }
    }
  }


  @include respond-down(small){
    .all-link{
      display: none;
    }
  }
}

