&.partners {
  overflow: hidden;
  @include respond-down(middle) {
  }

  .block.title {
    @include respond-down(middle) {
      justify-content: center;
      margin: 0 auto 10px;
    }

  }

  .partners__slider {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    .partners__item{
      display: inline-block !important;
    }
    &.slick-initialized{
      overflow: auto;
      white-space: normal;
      display: block;
      .partners__item{
        display: flex !important;
      }
    }
    @include respond-down(small){
      padding: 0 20px;
    }

    .slick-arrow {
      display: block;
      position: absolute;
      top: 50%;
      //width: 16px;
      //height: 32px;
      text-indent: -9999px;
      //background-image: url('../svg/arrow-prev.svg');
      background-repeat: no-repeat;
      background-color: $t;
      //transform: translateY(-50%);

      &.slick-prev {
        left: 0;

      }

      &.slick-next {
        right: 0;
        //transform: translateY(-50%) rotateZ(180deg);
      }
    }
  }

  .partners__item {
    padding: 0 0 10px 0;
    //filter: grayscale(100%);
    display: flex !important;
    height: 70px;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid $t;
    transition: all ease .2s;
    @include respond-down(middle) {
      height: 60px;
    }



    &:hover {
      filter: none;
      opacity: 1;
      border-color: $orange;
    }
  }

  .partners__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 100%;

    @include respond-down(small){
      max-width: 90px;
    }
  }


}
